import { useFormik } from "formik";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";
import { default as eyeSVG } from "../../../../../assets/media/icons/other_icons/Eye.svg";
import UploadSVG from "../../../../../assets/media/icons/other_icons/Upload.svg";
import AddSVG from "../../../../../assets/media/icons/other_icons/add_icon.svg";
import CrossSVG from "../../../../../assets/media/icons/standard_icons/cross.svg";
import Checkbox from "../../../../../components/formComponent/Checkbox";
import DropDownField from "../../../../../components/formComponent/DropDownField";

import { uploadFileWithPreAssignedURL } from "helpers/AssetHelpers";
import _, { isArray, size } from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import DELETESVG from "../../../../../assets/media/icons/other_icons/Delete.svg";
import EDITSVG from "../../../../../assets/media/icons/other_icons/edit-square.svg";
import TextInput from "../../../../../components/formComponent/TextInput";
import CopyToClipboard from "../../../../../components/fullPopup/CopyToClipboard";
import NoData from "../../../../../components/fullPopup/NoData";
import TableBody from "../../../../../components/fullPopup/TableBody";
import TableHeader from "../../../../../components/fullPopup/TableHeader";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../../../components/ui/ProgressBardLoader";
import Messages from "../../../../../helpers/Messages";
import { showAlert } from "../../../../../helpers/ShowAlert";
import DataCell from "../../../../Sales/component/fullOrderDetail/DataCell";
import DetailInfo from "../../../../Sales/component/fullOrderDetail/DetailInfo";
import OrderDetailBox from "../../../../Sales/component/fullOrderDetail/OrderDetailBox";
import { updateAddress } from "../../../../settings/core/_request";
import PDFViewer from "../../../wallet-bank_Details/component/bankViewComponent/PDFViewer";
import {
  addBankWallet,
  deleteKycFile,
  uploadKycFiles,
} from "../../../wallet-bank_Details/core/_request";
import { PersonalDetailsContext } from "../../core/PersonalDetailsProvider";
import { updateUserFormData } from "../../core/requests";
import AddressPopup from "./AddressPopup";

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  // base_currency: Yup.mixed().required("Base currency is required "),
  team_name: Yup.string().required("Team name is required"),
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  username: Yup.string().required("Username is required"),
});

const FullViewForm = ({
  viewForm,
  handleClosePopup,
  addressProp,
  refetchAddress,
  listRefetch,
  refetch,
}: any) => {
  const layout = useContext(PersonalDetailsContext);
  const [loader, setLoader] = useState(false);
  let PDFViewInitial = {
    fileName: "",
    file: "",
    page: 1,
    pageNumber: 1,
  };

  const [PDFView, setPDFView] = useState<any>(PDFViewInitial);

  // FILE DROPZONE
  const { getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    maxSize: 5244899, // 5MB in bytes
    onDrop: (acceptedFiles, rejectedFiles) => {
      setLoader(true);

      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) =>
              error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : "Invalid File Type"
            )
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        setLoader(false);

        showAlert(uniqueCodes?.join(", <br/>"), true);
      } else if (acceptedFiles) {
        const uniqueAcceptedFiles = _.uniqBy(acceptedFiles, "name");
        // HANDLE FILE UPLOAD IN STATE
        // AWS UPLOAD
        let awsUPloadedFiles: any = [];
        Promise.all(
          uniqueAcceptedFiles?.map(async (item: any, itemIndex: number) => {
            // const res: any = await mobileS3
            //   .uploadFile(item)
            //   .then((response: any) => {
            //     return response;
            //   });
            const res: any = await uploadFileWithPreAssignedURL(
              item,
              `${
                viewForm?.data?.seller_type === "Business"
                  ? "business-proof"
                  : "identity-proof"
              }/${viewForm?.data?.user_id}`
            );
            awsUPloadedFiles.push({
              key: res?.key?.split("/")?.pop(),
              file_name: item?.name,
            }); // Push an object with both key and name
          })
        ).then(() => {
          //CALL KYC FILE SUBMIT API
          let payload: any = {
            team_id: viewForm?.id,
            fileData: awsUPloadedFiles,
          };
          uploadKycFiles(payload).then((response: any) => {
            setLoader(false);
            refetchAddress();
            if (response?.data?.status) {
              showAlert(response?.message, false);
            } else if (response?.data && size(response?.errors) > 0) {
              if (size(response?.data) === 0) {
                showAlert(response?.errors?.[0], true);
              }

              if (!response?.data?.status) {
                showAlert(response?.errors?.join(", "), true);
              }
            } else {
              showAlert("Something went wrong", true);
            }
          });
        });
      }
    },
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "application/pdf": [],
    },
  });

  const [addressState, setAddressState] = useState<any>({
    status: false,
    formType: "Add",
    data: undefined,
    country_list: addressProp?.country_list,
  });

  let addressData = addressProp?.address_book;

  const initialPrimaryUserValues = {
    id: viewForm?.data?.id,
    team_name: viewForm?.data?.name ? viewForm?.data?.name : "",
    firstname: viewForm?.data?.firstname ? viewForm?.data?.firstname : "",
    lastname: viewForm?.data?.lastname ? viewForm?.data?.lastname : "",
    email: viewForm?.data?.email ? viewForm?.data?.email : "",
    username: viewForm?.data?.username ? viewForm?.data?.username : "",
    vat_registered: viewForm?.data?.vat_registered
      ? viewForm?.data?.vat_registered
      : false,
    risk_level_id: layout.filterOptions?.risk_levels?.find(
      (item: any) => item?.id === viewForm?.data?.risk_level_id
    ),
    tx_trade_margin: viewForm?.data?.tx_trade_margin
      ? viewForm?.data?.tx_trade_margin
      : "",
    tx_trade_user: viewForm?.data?.tx_trade_user,
  };

  // FORMIk
  const formik = useFormik({
    initialValues: initialPrimaryUserValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      setLoader(true);
      let finalValues = {
        ...values,
        risk_level_id: values?.risk_level_id?.id,
      };
      updateUserFormData(values?.id, finalValues).then((response: any) => {
        setLoader(false);

        if (response?.data?.status) {
          setTimeout(() => {
            showAlert(response?.message, false);
            listRefetch();
            handleClosePopup();
          }, 500);
        } else {
          if (size(response?.data) === 0 || response?.errors) {
            showAlert(
              response?.errors?.title
                ? response?.errors?.title
                : Array.isArray(response?.errors)
                ? response?.errors?.join("<br/>")
                : "Something went wrong!",
              true
            );
          }
        }
      });
    },
  });

  // HANDLE CLOSE FORM
  const handleCloseForm = () => {
    setAddressState({
      status: false,
      formType: "Add",
      data: {
        team_id: viewForm?.id,
      },
      country_list: undefined,
    });
  };

  // HANDLE KYC FILE DELETE
  const handleDeleteKycFile = (item: any) => {
    setLoader(true);
    let payload: any = {
      ...item,
      team_id: viewForm?.id,
    };

    deleteKycFile(payload).then((response: any) => {
      setLoader(false);
      refetchAddress();

      if (response?.data?.status) {
        showAlert(response?.message, false);
      } else if (response?.data && size(response?.errors) > 0) {
        if (size(response?.data) === 0) {
          showAlert(response?.errors?.[0], true);
        }

        if (!response?.data?.status) {
          showAlert(response?.errors?.join(", "), true);
        }
      } else {
        showAlert("Something went wrong", true);
      }
    });
  };

  const handleAddWallet = async (id: any) => {
    setLoader(true);
    await addBankWallet("create", id)
      .then(async (res) => {
        if (res?.data?.status) {
          await refetch();
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  console.log(formik?.errors, formik?.touched, "********88");

  return (
    <form>
      <div
        className={`whiteFormFields show outline-black overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full !h-full upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg transition duration-300 ease-in-out font-medium w-full md:max-w-[calc(100%_-_3.75rem)] z-[50]  max-h-[calc(100%-3.75rem)] !bg-gray-650 `}
      >
        <div className="relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600 h-full">
          <div className=" main-content-wrap  min-h-full bg-gray-650 ">
            <div className="border-t flex justify-between md:pl-8 pl-5 items-center border-b sticky top-0 bg-white z-[60]">
              <div className="flex-1">
                {/* <span className="md:text-sm15 text-sm12 block font-semibold">
                  {" "}
                  Tixstock ID:
                </span> */}
              </div>
              <div className="flex flex-wrap">
                <button
                  className={`group relative sadsadsad mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l`}
                  type="button"
                  id="Close"
                  onClick={handleClosePopup}
                >
                  <IKTSVG
                    className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition`}
                    path={CrossSVG}
                    svgClassName="w-[.5966rem] h-[.5966rem]"
                  />

                  <Tooltip
                    anchorId={`Close`}
                    content={`Close`}
                    place={"left"}
                    variant="light"
                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                  />
                </button>
              </div>
            </div>

            {/* PRIMARY USER  - BODY */}
            <div className="inner-content p-5 bg-gray-650">
              <div className="table-block border rounded mb-5 bg-white">
                {/* HEADER */}
                <div className="title border-b md:px-5 px-3 py-[9px] flex justify-between">
                  <span className="md:text-sm15 text-sm12 block font-semibold">
                    {" "}
                    Update primary user
                  </span>
                </div>

                {/* BODY */}
                <form className="inventory-table sales-table overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full ">
                  <div className="flex flex-wrap px-[.9375rem] py-5 new-inp gap-y-5">
                    {/* TEAM NAME */}
                    <div className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem] relative">
                      <TextInput
                        required={false}
                        name="team_name"
                        type="text"
                        label="Team name"
                        value={formik.values.team_name}
                        errorClass={
                          formik.touched.team_name &&
                          formik.errors.team_name &&
                          "border-rose-500"
                        }
                        id="team_name"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("team_name", "")
                        }
                      />

                      {formik.touched.team_name && formik.errors?.team_name && (
                        <div className="text-xxs text-rose-500 error-msg left-3">
                          {formik.errors?.team_name as string}
                        </div>
                      )}
                    </div>

                    {/* FIRST NAME */}
                    <div className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem] relative">
                      <TextInput
                        // required={false}
                        name="firstname"
                        type="text"
                        label="First name"
                        value={formik.values.firstname}
                        errorClass={
                          formik.touched.firstname &&
                          formik.errors.firstname &&
                          "border-rose-500"
                        }
                        id="firstname"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("firstname", "")
                        }
                      />
                      {formik.touched.firstname && formik.errors?.firstname && (
                        <div className="text-xxs text-rose-500 error-msg left-3">
                          {formik.errors?.firstname as string}
                        </div>
                      )}
                    </div>

                    {/* LAST NAME */}
                    <div className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem] relative">
                      <TextInput
                        required={false}
                        name="lastname"
                        type="text"
                        label="Last name"
                        value={formik.values.lastname}
                        errorClass={
                          formik.touched.lastname &&
                          formik.errors.lastname &&
                          "border-rose-500"
                        }
                        id="lastname"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("lastname", "")
                        }
                      />
                      {formik.touched.lastname && formik.errors?.lastname && (
                        <div className="text-xxs text-rose-500 error-msg left-3">
                          {formik.errors?.lastname as string}
                        </div>
                      )}
                    </div>

                    {/* EMAIL ADDRESS */}
                    <div className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem] relative">
                      <TextInput
                        required={false}
                        name="email"
                        type="text"
                        label="Email address"
                        value={formik.values.email}
                        errorClass={
                          formik.touched.email &&
                          formik.errors.email &&
                          "border-rose-500"
                        }
                        id="email"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("email", "")
                        }
                      />
                      {formik.touched.email && formik.errors?.email && (
                        <div className="text-xxs text-rose-500 error-msg left-3">
                          {formik.errors?.email as string}
                        </div>
                      )}
                    </div>

                    {/* USERNAME */}
                    <div className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem] relative">
                      <TextInput
                        required={false}
                        name="username"
                        type="text"
                        label="Username"
                        value={formik.values.username}
                        errorClass={
                          formik.touched.username &&
                          formik.errors.username &&
                          "border-rose-500"
                        }
                        id="username"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("username", "")
                        }
                      />
                      {formik.touched.username && formik.errors?.username && (
                        <div className="text-xxs text-rose-500 error-msg left-3">
                          {formik.errors?.username as string}
                        </div>
                      )}
                    </div>

                    {/* VAR REGISTERED */}
                    <div className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem] checkbox_label">
                      <Checkbox
                        label="VAT registered"
                        id="vat_registered"
                        name="vat_registered"
                        extraClass="w-full h-full pros [&_input]:mr-2 min-h-[2.5rem]"
                        labelclass="!text-sm14 !pl-[.6875rem]"
                        checked={formik?.values?.vat_registered}
                        tooltip={false}
                        handleOnChange={(data: any, e: any) => {
                          formik.setFieldValue(
                            "vat_registered",
                            data?.target?.checked
                          );
                        }}
                      />
                    </div>

                    {/* TX TRADE USER */}
                    <div className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem]">
                      <Checkbox
                        label="TX Trade user"
                        id="tx_trade_user"
                        name="tx_trade_user"
                        extraClass="w-full h-full [&_input]:mr-2 min-h-[2.5rem]"
                        labelclass="!text-sm14 !pl-[.6875rem]"
                        checked={formik?.values?.tx_trade_user}
                        tooltip={false}
                        handleOnChange={(data: any, e: any) => {
                          formik.setFieldValue(
                            "tx_trade_user",
                            data?.target?.checked
                          );
                        }}
                      />
                    </div>

                    {/* RISK LEVEL */}
                    <div className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem]">
                      <DropDownField
                        options={layout.filterOptions?.risk_levels}
                        placeholder="Risk level"
                        isClearable={true}
                        divClassName={`w-full h-full relative`}
                        className={` ${
                          formik.touched.risk_level_id &&
                          formik.errors?.risk_level_id &&
                          "all-dropdown-errorField"
                        }`}
                        name="risk_level_id"
                        value={formik.values.risk_level_id}
                        handleOnChange={(data: any) => {
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          );
                        }}
                      ></DropDownField>
                    </div>

                    {/* TX TRADE MARGIN */}
                    <div className="single_list flex items-center text-xs p-0 md:w-1/4 w-full px-[.3125rem]">
                      <TextInput
                        required={false}
                        name="tx_trade_margin"
                        type="text"
                        label="TX Trade margin (%)"
                        value={formik.values.tx_trade_margin}
                        errorClass={
                          formik.touched.tx_trade_margin &&
                          formik.errors.tx_trade_margin &&
                          "border-rose-500"
                        }
                        id="tx_trade_margin"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("tx_trade_margin", "")
                        }
                      />
                    </div>

                    {/* SUBMIT BUTTON */}
                    <div className="flex flex-wrap l_btn  items-center w-full px-[.3125rem]">
                      <button
                        type="button"
                        className="relative z-10 border border-green-600 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 "
                        onClick={() => formik.handleSubmit()}
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {/* ADDRESSES - BODY */}
              <div className="table-block border rounded mb-5 bg-white">
                {/* HEADER */}
                <div className="title border-b md:px-5 px-3 py-[9px] flex justify-between">
                  <span className="md:text-sm15 text-sm12 block font-semibold">
                    {" "}
                    Addresses
                  </span>

                  <button
                    type="button"
                    className={`group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
                    onClick={() => {
                      setAddressState((current: any) => {
                        return {
                          ...current,
                          status: true,
                          formType: "Add",
                          data: {
                            team_id: viewForm?.id,
                          },
                          country_list: addressProp?.country_list,
                        };
                      });
                    }}
                  >
                    <IKTSVG
                      className={`flex items-center justify-center fill-white transition mr-2 `}
                      path={AddSVG}
                      svgClassName="w-3.5 h-3"
                    />
                    Add
                  </button>
                </div>

                {/* BODY */}
                <TableBody renderArray={addressData}>
                  <thead className="text-xs text-gray-400">
                    <tr className="border-b">
                      {/* Address Tittle */}
                      <TableHeader
                        title="Address title"
                        width="w-[15.625rem]"
                      />

                      {/* FIRST NAME */}
                      <TableHeader title="First name" width="w-[15.625rem]" />

                      {/* LAST NAME */}
                      <TableHeader title="Last name" width="w-[15.625rem]" />

                      {/* ADDRESS 1 */}
                      <TableHeader
                        title="Address Line 1"
                        width="w-[15.625rem]"
                      />

                      {/* POSTALCODE */}
                      <TableHeader title="Postcode" width="w-[15.625rem]" />

                      {/* ADDRESS TYPE */}
                      <TableHeader title="Address type" width="w-[15.625rem]" />

                      <th className="relative right-0 ml-auto  p-0 w-[7.5625rem]">
                        <div
                          className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] bg-white"
                          id="left-shad-list"
                        >
                          {/* <button
                            className="group rounded-l  hover: transition px-[.5625rem] py-2"
                            id="leftArrow"
                            type="button"
                            // onClick={() => scroll(-120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={LeftSVG}
                              svgClassName="w-[.375rem] h-[.5625rem]"
                            />
                          </button>
                          <button
                            className="group rounded-r  transition px-[.5625rem] py-2"
                            id="rightArrow"
                            type="button"
                            // onClick={() => scroll(120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={RightSVG}
                              svgClassName="w-[.375rem] h-[.5625rem]"
                            />
                          </button> */}
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {addressData &&
                      size(addressData) > 0 &&
                      addressData?.map((item: any, index: number) => {
                        return (
                          <tr className="border-b">
                            <DataCell title={item?.address_name} />
                            <DataCell title={item?.firstname} />
                            <DataCell title={item?.lastname} />
                            <DataCell title={item?.address_1} />
                            <DataCell title={item?.postcode} />
                            <DataCell
                              title={
                                item?.default
                                  ? "Default address"
                                  : "Additional Address"
                              }
                            />
                            <td
                              className={`relative right-0 ml-auto p-0 icon_td min-w-[3.75rem]`}
                            >
                              <div className="bg-opacity-[7%]">
                                <div
                                  className="flex items-center gap-2 justify-center relative w-full h-full border-l"
                                  id="left-shad-list"
                                >
                                  <button
                                    type="button"
                                    className={`group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2 gap-2 p-2 `}
                                    onClick={() => {
                                      setAddressState((current: any) => {
                                        return {
                                          ...current,
                                          status: true,
                                          formType: "Edit",
                                          data: {
                                            ...item,
                                            team_id: viewForm?.id,
                                          },
                                          country_list:
                                            addressProp?.country_list,
                                        };
                                      });
                                    }}
                                  >
                                    {/* Edit */}
                                    <IKTSVG
                                      className=" flex items-center justify-center fill-white group-hover:fill-white-500"
                                      path={EDITSVG}
                                      svgClassName="w-3 h-3"
                                    />
                                  </button>
                                  {!item.default && (
                                    <button
                                      type="button"
                                      className={`group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2 gap-2 p-2 `}
                                      onClick={() => {
                                        const data = {
                                          remove_address_id: item.id,
                                        };
                                        updateAddress(data).then(
                                          (response: any) => {
                                            if (
                                              response?.data?.status === true
                                            ) {
                                              showAlert(
                                                response?.message,
                                                false
                                              );
                                              refetchAddress();
                                            } else {
                                              showAlert(
                                                Array.isArray(response?.errors)
                                                  ? response?.errors.join(
                                                      "<br/>"
                                                    )
                                                  : Messages?.someWrong,
                                                true
                                              );
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      {/* Delete */}
                                      <IKTSVG
                                        className=" flex items-center justify-center fill-white group-hover:fill-white-500"
                                        path={DELETESVG}
                                        svgClassName="w-3 h-3"
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </TableBody>
              </div>

              {/* PACKAGE ADD ON */}
              <div className="flex flex-wrap -mx-2.5">
                <OrderDetailBox
                  title="Manage package and Add-Ons"
                  className="flex flex-wrap px-3 py-3"
                >
                  <DetailInfo
                    header="Package Name"
                    value={
                      viewForm?.data?.package_name
                        ? viewForm?.data?.package_name
                        : "-"
                    }
                  />
                  <DetailInfo
                    header="Base Currency"
                    value={
                      viewForm?.data?.base_currency
                        ? viewForm?.data?.base_currency
                        : "-"
                    }
                  />
                </OrderDetailBox>

                {/* PDF VIEW */}
                <div
                  className={`${PDFView?.file ? "show" : "hidden"} 
                popup input-style2 upload-ticket-popup bg-white offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-1000 ease-in-out font-medium z-60 w-full max-w-[46.25rem] md:w-[43.125rem]`}
                >
                  {PDFView?.file && (
                    <PDFViewer
                      PDFView={PDFView}
                      setPDFView={setPDFView}
                      isRightSidePopup={true}
                    />
                  )}
                </div>
                {/* KYC DOCUMENTS */}
                <OrderDetailBox
                  title="KYC documents"
                  className="flex flex-wrap px-3 py-3"
                >
                  <div className="inner-part px-2 py-3 flex flex-wrap w-full">
                    <div
                      className={`flex flex-col items-center justify-center text-center  w-full min-h-[5rem] border border-dashed border-violet-500/10 rounded bg-violet-300/50 text-xs mb-[1rem]`}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-2.5">Click to upload</div>
                      <button
                        type="button"
                        onClick={open}
                        className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
                      >
                        <IKTSVG
                          className="fill-white mr-2"
                          path={UploadSVG}
                          svgClassName="w-[.8125rem] h-[.6875rem]"
                        />
                        Upload
                      </button>
                    </div>

                    {!(size(viewForm?.data?.kyc_docs) > 0) && <NoData />}
                    {isArray(viewForm?.data?.kyc_docs) &&
                      viewForm?.data?.kyc_docs?.map(
                        (item: any, index: number) => {
                          return (
                            <div className="list_wrap flex flex-wrap gap-2.5 anim-fade w-full mb-2">
                              <div className="single-invoice border rounded flex flex-wrap justify-between px-2.5 py-1.5 text-sm12 bg-white w-full">
                                <span className="flex-1 flex leading-4">
                                  {item?.name}
                                </span>

                                <button
                                  className="ml-2"
                                  type="button"
                                  id={`pdf-view-account-${index}`}
                                  onClick={() => {
                                    setPDFView((current: any) => {
                                      return {
                                        ...current,
                                        fileName: item?.name,
                                        file: item?.key,
                                      };
                                    });
                                  }}
                                >
                                  <Tooltip
                                    anchorId={`pdf-view-account-${index}`}
                                    content={"View"}
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                  />
                                  <IKTSVG
                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                    path={eyeSVG}
                                    svgClassName="w-[.9375rem] h-2.5"
                                  />
                                </button>

                                <button
                                  className="ml-2"
                                  type="button"
                                  id={`kyc-delete-file-${index}`}
                                  onClick={() => handleDeleteKycFile(item)}
                                >
                                  <Tooltip
                                    anchorId={`kyc-delete-file-${index}`}
                                    content={"Delete"}
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                  />
                                  <IKTSVG
                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                    path={CrossSVG}
                                    svgClassName="w-[.5625rem] h-[.5625rem]"
                                  />
                                </button>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>

                  {/* WALLET STATUS */}
                  <DetailInfo
                    header="Seller wallet status"
                    value={`${
                      viewForm?.data?.wallet_status === 1
                        ? "Active"
                        : viewForm?.data?.wallet_status === 0
                        ? "Pending"
                        : "N/A"
                    }`}
                    valueClassName="text-sm12 break-words flex justify-between items-center"
                  >
                    {viewForm?.data?.wallet_status === -1 && (
                      <button
                        type="button"
                        className={`group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
                        onClick={() => handleAddWallet(viewForm?.data?.id)}
                      >
                        <IKTSVG
                          className={`flex items-center justify-center fill-white transition mr-2 `}
                          path={AddSVG}
                          svgClassName="w-3.5 h-3"
                        />
                        Add
                      </button>
                    )}
                  </DetailInfo>
                </OrderDetailBox>

                {/* CARD */}
                <OrderDetailBox
                  title="Card/Token"
                  className="flex flex-wrap px-3 py-3"
                >
                  <DetailInfo
                    header="Card"
                    value={
                      viewForm?.data?.card_data?.display
                        ? viewForm?.data?.card_data?.display
                        : "-"
                    }
                  />

                  <DetailInfo
                    header="Created at"
                    value={
                      viewForm?.data?.card_data?.created_at
                        ? moment
                            .unix(viewForm?.data?.card_data?.created_at)
                            .format("DD MMMM YYYY")
                        : "-"
                    }
                  />

                  <CopyToClipboard
                    header="Card token"
                    value={viewForm?.data?.card_data?.token}
                  />
                </OrderDetailBox>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ADDRESS POPUP */}
      <AddressPopup
        viewForm={viewForm}
        data={addressState}
        handleCloseForm={handleCloseForm}
        refetchAddress={refetchAddress}
      />

      {/* LOADER */}
      {loader && <ProgressBardLoader secondCounter={1.5} />}
    </form>
  );
};

export default FullViewForm;
